/*
 *		BOOTSTRAP HELPER
 */

.btn .glyphicons,
.btn .halflings,
.btn .filetypes,
.btn .social{
	margin-right:.3em;
}
.dropdown-menu li .glyphicons,
.dropdown-menu li .halflings,
.dropdown-menu li .filetypes,
.dropdown-menu li .social{
	margin-right:.2.9em;
	margin-top:.19em;
	margin-right:.3em;
}
.list-group .list-group-item .glyphicons,
.list-group .list-group-item .halflings,
.list-group .list-group-item .filetypes,
.list-group .list-group-item .social{
	margin-right:.5em;
}

a.btn 
{
line-height: 1.12;
}

